import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import { withPrefix } from 'gatsby'
import { Wrapper, Section } from '../components/baseStyles'
import Paragraph from '../components/paragraph'
import CustomButton from '../components/button'
import contact from '../images/contact/contact.svg'
import { FormattedMessage } from 'react-intl'

import SEO from '../components/seo'
import { blue } from '../utlis/colors'

const Span = styled.span`
  font-family: 'Grotesk-Bold';
`
const Info = styled.div`
  align-items: flex-end;
  width: 75%;
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
const Title = styled.p`
  text-transform: uppercase;
  color: #9b9b9b;
  font-size: 13px;
  margin-bottom: 10px;
  font-family: 'Grotesk-SemiBold';
  letter-spacing: 1px;
`

const Description = styled.p`
  color: #9b9b9b;
  font-size: 15px;
  line-height: 19px;
  font-family: 'Grotesk-Medium';
  width: calc((100% / 12) * 8);
  margin-left: calc((100% / 12) * 2);
  margin-bottom: 70px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`
const A = styled.a`
  text-decoration: none;
  color: ${blue};
  &:hover {
    color: '#133479';
    text-decoration: underline;
    transition: all ease 0.2s;
  }
`

const ContactUs = ({ location, pageContext: { locale } }) => {
	const isHomePage = location.pathname === withPrefix('/')
	const isContactUs = location.pathname.includes('/contact')
	return (
		<Layout
			heroImage={contact}
			isContactUs={isContactUs}
			isHomePage={isHomePage}
			title={locale === 'hr' ? 'Kontakt' : 'Contact'}
			text={
				locale === 'hr'
					? 'Javi nam se za sastanak'
					: 'Get in touch and book an appointment'
			}
			locale={locale}
		>
			<SEO
				title={
					locale === 'hr'
						? 'Kontakt | Sve informacije na jednom mjestu | Litto za Partnere'
						: 'Contact Us | Get in touch with our agents | Litto For Partners'
				}
				description={
					locale === 'hr'
						? 'Nešto nam je promaklo ili nas želiš kontaktirati za dodatna pitanja oko apartmana ili iznajmljivanja? Na pravom si mjestu. Pošalji nam upit.'
						: 'We missed out on some crucial information or you’d simply like to find out more about renting in tourism? You’re at the right place. Send us a message.'
				}
				keywords={['Litto']}
				lang={locale}
				isContactPage={true}
			/>
			<Wrapper style={{ marginTop: '50px' }}>
				<Section>
					<Info>
						<div style={{ marginBottom: '40px' }}>
							<Paragraph>
								<Span>Litto d.o.o </Span>&#x2015;{' '}
								<FormattedMessage id="contactTouristAgency" />
							</Paragraph>
							<Paragraph>Lovački put 7</Paragraph>
							<Paragraph>
								21000 Split &#x2015; {locale === 'hr' ? 'Hrvatska' : 'Croatia'}
							</Paragraph>
						</div>
						<div>
							<Title>
								{locale === 'hr' ? 'Kontakt informacije' : 'Contact info'}
							</Title>

							<Paragraph>
								<A href="tel:385911770310" title="Litto agency">
									+ 385 91 177 0310
								</A>
							</Paragraph>
							<Paragraph>
								<A href="tel:385911770312" title="Litto agency">
									+ 385 91 177 0312
								</A>
							</Paragraph>
							<Paragraph>
								<A href="mailto:info@litto.agency" title="Litto agency">
									info@litto.agency
								</A>
							</Paragraph>
						</div>
					</Info>
					<div style={{ marginBottom: '50px' }}>
						<Title>{locale === 'hr' ? 'Radno vrijeme' : 'Working Hours'}</Title>
						<Paragraph>
							{locale === 'hr'
								? 'Ponedjeljak - Petak — od 09:00 do 17:00h'
								: 'Monday - Friday — from 09:00 to 17:00h'}
						</Paragraph>
						<Paragraph>
							<FormattedMessage id="contactSatSun" /> &#x2015;{' '}
							<FormattedMessage id="contactClosed" />{' '}
						</Paragraph>
						<Paragraph>
							<FormattedMessage id="contactPerson" />
						</Paragraph>
					</div>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.google.com/maps/place/Litto+HQ+-+Travel+and+Property+management+Agency/@43.5129198,16.484668,19z/data=!3m1!4b1!4m5!3m4!1s0x13355f578b987dbf:0xeee5a890e96f088a!8m2!3d43.5129188!4d16.4852152"
						title="Litto agency HQ"
					>
						<CustomButton>
							<FormattedMessage id="contactGoogleMaps" />
						</CustomButton>
					</a>
				</Section>
				<Description style={{ marginTop: "50px" }}>
					Copyright 2022 | All Rights Reserved | Litto d.o.o. - za usluge turističke agencije, Lovački put 7, 21000 Split, Hrvatska | OIB: 32528484119 | ID: HR-AB-21-060397269 | Broj računa: Zagrebačka Banka d.d., 10000 Zagreb, IBAN: HR5723600001102736084 | MBS: 060397269 | Temeljni kapital trgovačkog društva: 20.000,00 kn, uplaćen u cijelosti. | Član uprave i direktor: Iva Kegalj
				</Description>
			</Wrapper>
		</Layout>
	)
}

export default ContactUs
